import React from 'react';
import {
    CardBody, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import _ from 'lodash';
import utilService from '../../common/utilService'
import utilService2 from '../../services/utilService'
import { COLORS, ORDER_SERVICE_TYPE, ORDER_STATUS, ORDER_BOOKING_TYPE } from '../../components/Common/constants';
import moment from 'moment'

///////////////////////
const OrderDetail = (props) => {
    const { order, isOpenDetail, onCloseDetail, style, isTemplateMode = false } = props;

    const onClose = () => {
        onCloseDetail();
    };

    const onPrint = () => {
        window.print();
        console.log("androidPrintAction:" + JSON.stringify(orderForAndroid));//to make Android priniting possible
    }

    const getFee = (order_item) => {
        if (order_item.deliveryCharge) {
            return (
                (order_item.currencySymbol??' ') + order_item.deliveryCharge.toFixed(2)
            )
        }
        return order_item.currencySymbol + '0'
    }

    const getFeeAmount = (order) => {
        // if(order.transcationFee){
        //     return order.transcationFee.toFixed(2)
        // }
        if (order.deliveryCharge) {
            return order.deliveryCharge.toFixed(2)
        }

        return 0;
    }
    const getTotalAmount = (order) => {
        return order.currencySymbol + (parseFloat(order.subTotalAmount) + parseFloat(getFeeAmount(order))).toFixed(2);
    }

    const getAddonName = (addedOnData) => {
        if (typeof addedOnData.name === "object") {
            return localStorage.getItem('jq-appLang') === "en" ? addedOnData.name.en : addedOnData.name.nl
        }

        return addedOnData.name;
    }

    const renderContent = () => (
        <>
            <hr style={{ borderTopWidth: '2px', margin: 0 }} />
            <CardBody className="d-flex" style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0 }}>
                <div>
                    {orderForAndroid.placedAt}
                </div>
                <div style={{ color: '#21478F', textAlign: 'end' }}>
                    {orderForAndroid.created}
                </div>
            </CardBody>
            {
                order.bookingType === ORDER_BOOKING_TYPE.SCHEDULE &&
                <CardBody className="d-flex" style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0 }}>
                    <div>{utilService.getLangByCode('Desired time')}</div>
                    <div style={{ color: '#21478F' }}>
                        {orderForAndroid.due}
                    </div>
                </CardBody>
            }
            <CardBody className="d-flex" style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0, flexDirection: 'row', }}>
                <div className="mr-3 w-50">{utilService.getLangByCode('Delivery address')}</div>
                {
                    order.serviceType === ORDER_SERVICE_TYPE.delivery &&
                    <div style={{ color: '#21478F', textAlign: 'left' }}>
                        {orderForAndroid.dropAddress} 
                    </div>
                }
                {
                    order.serviceType === ORDER_SERVICE_TYPE.pickup &&
                    <div style={{ color: '#21478F' }}>
                        {utilService.getLangByCode('Pickup')}
                        {/* {utilService.getLangByCode("Address")} */}
                    </div>
                }
            </CardBody>
            <CardBody className="d-flex" style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0 }}>
                <div>{utilService.getLangByCode('Customer details')}</div>
                <div style={{ color: '#21478F' }}>
                    <span>
                        <span>{orderForAndroid.customerName} - </span>
                        <span>{orderForAndroid.customerMobile}</span>
                    </span>
                </div>
            </CardBody>

            <hr style={{ borderTopWidth: '2px', margin: 0 }} />
            <CardBody className="d-flex" style={{ justifyContent: 'space-between', color: '#21478F', fontWeight: 'bold', paddingLeft: 0, paddingRight: 0 }}>
                <div>{utilService.getLangByCode('Item')}</div>
                <div>{utilService.getLangByCode('Price')}</div>
            </CardBody>
            <hr style={{ borderTopWidth: '2px', margin: 0 }} />
            {
                orderForAndroid.items && orderForAndroid.items.map((data, index) => {
                    return (
                        <div style={{ paddingLeft: 0, paddingRight: 0 }} key={`SubItem${index}`}>
                            <CardBody className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', paddingLeft: 0, paddingRight: 0 }}>
                                {/* <div className="d-flex" style={{justifyContent:'space-between'}}> */}
                                <div style={{ flex: 2 }}>{data.itemName}</div>
                                <div style={{ flex: 1 }}>{data.quantity + " x " + orderForAndroid.currencySymbol + data.unitPrice.toFixed(2)}</div>
                                {/* </div> */}
                                {/* <div style={{color:'#21478F'}}>{order.currencySymbol + data.finalPrice.toFixed(2)}</div> */}
                                <div style={{ color: '#21478F' }}>{orderForAndroid.currencySymbol + (data.quantity * data.unitPrice).toFixed(2)}</div>
                            </CardBody>
                            {data.addOns && data.addOns.map((addOnData, addOnIndex) => {
                                return (
                                    <div key={`addonItem${addOnIndex}`}>
                                        {
                                            addOnData.addOnGroup && addOnData.addOnGroup.map((addedOnData, sub_index) => {
                                                if (addedOnData.price !== undefined) {
                                                    return (
                                                        <CardBody className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', paddingLeft: '30px', paddingRight: 0, fontSize: '12px' }}
                                                            key={`SubAddOnItem${sub_index}`}>
                                                            <div style={{ flex: 2 }}>{"+" + getAddonName(addedOnData)}</div>
                                                            <div style={{ flex: 1 }}>{data.quantity + " x " + order.currencySymbol + Number(addedOnData.price).toFixed(2)}</div>
                                                            <div style={{ color: '#21478F' }}>{orderForAndroid.currencySymbol + (data.quantity * Number(addedOnData.price)).toFixed(2)}</div>
                                                        </CardBody>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    )
                })
            }
            <hr style={{ borderTopWidth: '2px', margin: 0 }} />
            <CardBody className="d-flex" style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0 }}>
                <div>{utilService.getLangByCode('Delivery fee')}</div>
                <div style={{ color: '#21478F' }}>
                    {<div>{orderForAndroid.deliveryFee}</div>}
                </div>
            </CardBody>
            <hr style={{ borderTopWidth: '2px', margin: 0 }} />
            <CardBody className="d-flex" style={{ justifyContent: 'space-between', color: '#21478F', fontWeight: 'bold', paddingLeft: 0, paddingRight: 0 }}>
                <div>{utilService.getLangByCode('Total')}</div>
                {order.currencySymbol && order.subTotalAmount &&
                    <div style={{ color: '#21478F', fontWeight: 'bold' }}>{orderForAndroid.totalAmount}</div>
                }
            </CardBody>
            {orderForAndroid.extraNote ?
                <>
                    <hr style={{ borderTopWidth: '2px', margin: 0 }} />
                    <CardBody className="d-flex" style={{
                        justifyContent: 'space-between', color: '#21478F',
                        fontWeight: 'bold', paddingLeft: 0, paddingRight: 0
                    }}>
                        <div>{utilService.getLangByCode('Notes')}</div>
                        <div style={{ color: '#21478F', fontWeight: 'bold', marginLeft: "30px" }}>{orderForAndroid.extraNote}</div>
                    </CardBody>
                </>
            : null}

            <CardBody className="d-flex" style={{ justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                <div className="d-flex" style={{ justifyContent: 'center' }}>
                    <button
                        className="btn bg-btn-approve text-white"
                        onClick={() => {
                            onClose()
                        }}
                        style={{ width: '100px', borderWidth: '2px' }}
                    >
                        {utilService.getLangByCode('Close')}
                    </button>
                    <button
                        id="printBtn"
                        className="btn bg-red"
                        onClick={() => { onPrint() }}
                        style={{ width: '100px', borderWidth: '2px', borderColor: COLORS.RED, color: COLORS.WHITE, marginLeft: '20px' }}
                    >
                        {utilService.getLangByCode('Print')}
                    </button>
                </div>
            </CardBody>
        </>
    )

    const renderModal = () => (
        <Modal
            isOpen={isOpenDetail}
            //visible = {MainStore.visible}
            toggle={() => {
                onClose()
            }}
            style={style || { marginTop: '200px', lineHeight: '0.05rem' }}
            className="font-arial-bold"
            id="printSection"
        >
            {
                order &&
                <div>
                    <ModalHeader className="text-center" style={{ marginTop: '100px', padding: '0', border: 0 }}>
                        <div style={{ marginTop: "-170px" }}>
                            <img className="img-fluid" src="img/notify_logo.svg" alt="App Logo" style={{ width: '140px', height: '140px' }} />
                        </div>
                        {
                            <p style={{ color: COLORS.GENERAL, fontSize: '20px' }}>
                                {orderForAndroid.orderHeader}
                            </p>
                        }
                    </ModalHeader>

                    <ModalBody style={{ marginLeft: '30px', marginRight: '30px', fontSize: '16px', lineHeight: 1 }}>
                        {renderContent()}
                    </ModalBody>
                </div>}
            <div style={{ marginBottom: '20px' }} />
        </Modal>
    )

    const renderTemplate = () => (
        <div id="printSection">
            {renderContent()}
        </div>
    )

    // useEffect(() => {
    //     //console.log("**Order Detail**", order);
    // }, [])
    let orderForAndroid = {};
    if (order) {
        orderForAndroid.orderHeader = (order.status === ORDER_STATUS.created && order.serviceType === ORDER_SERVICE_TYPE.delivery ?
            utilService.getLangByCode('Delivery new order details')
            : order.status === ORDER_STATUS.created && order.serviceType === ORDER_SERVICE_TYPE.pickup ?
                utilService.getLangByCode('Pickup new order details')
                : order.status === ORDER_STATUS.accepted ?
                    utilService.getLangByCode('Accepted order details')
                    : (order.status === ORDER_STATUS.orderReady || order.status === ORDER_STATUS.orderPicked) ?
                        utilService.getLangByCode('Pickup ready order details')
                        : order.status === ORDER_STATUS.inDispatch ?
                            utilService.getLangByCode('Delivery ready order details')
                            : (order.status === ORDER_STATUS.orderCompleted || order.status === ORDER_STATUS.orderDeliveryCompleted) ?
                                utilService.getLangByCode('Completed order details')
                                : order.status === ORDER_STATUS.orderExpired ?
                                    utilService.getLangByCode('Expired order details')
                                    : order.status === ORDER_STATUS.cancelled ?
                                        utilService.getLangByCode('Cancelled order details')
                                        : order.status === ORDER_STATUS.rejected ?
                                            utilService.getLangByCode('Rejected order details')
                                            : "");
        orderForAndroid.placedAt = utilService.getLangByCode('Order placed at');
        orderForAndroid.created = utilService2.formatDate(order.timeStamp.created.isoDate) + "-" + utilService2.formatDate(order.timeStamp.created.isoDate, "HH:mm");
        orderForAndroid.due = utilService2.formatDate(order.dueDatetime) + "-" + utilService2.formatDate(order.dueDatetime, "HH:mm"); // moment(order.bookingDate).format('MM.DD.YY') + "-" + moment(order.bookingDate).format('HH:mm')
        orderForAndroid.dropAddress = order.drop.addressLine1;
        orderForAndroid.customerMobile = order.customerDetails.countryCode + order.customerDetails.mobile;
        orderForAndroid.customerName = order.customerDetails.name;
        orderForAndroid.items = order.Items;
        orderForAndroid.currencySymbol = order.currencySymbol;
        orderForAndroid.deliveryFee = getFee(order);
        orderForAndroid.totalAmount = getTotalAmount(order);
        
        orderForAndroid.donation = (order.accouting.donate??0).toFixed(2);
        orderForAndroid.txFee = (order.transcationFee??0).toFixed(2);
    }
    const html = isTemplateMode ? renderTemplate() :  renderModal();


    return html;
}

export default OrderDetail;
