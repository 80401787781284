import React, { Suspense, lazy, useEffect, useState } from 'react';
import { withRouter, Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

// import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage' ;
import BaseHorizontal from './components/Layout/BaseHorizontal';
import OrdersNotification from './views/orders/orderNotification';
import axios from 'axios';
import { AXIOS_AUTH, BROWSER_DEVICE_ID, getBrowserId, getOS, OS } from './components/Common/constants';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;

const SingleView = lazy(() => import('./components/SingleView/SingleView'));
const SubMenu = lazy(() => import('./components/SubMenu/SubMenu'));
const Home = lazy(() => import('./views/home/home'));
const Website = lazy(() => import('./views/website/website'));
const Menu = lazy(() => import('./views/menu/menu'));
const Settings = lazy(() => import('./views/settings/settings'));
const Help = lazy(() => import('./views/help/help'));
const Profile = lazy(() => import('./views/profile/profile'));
const Brand = lazy(() => import('./views/brand/brand'));
const Schedule = lazy(() => import('./views/schedule/schedule'));
const Manager = lazy(() => import('./views/manager/manager'));
const Invoice = lazy(() => import('./views/invoice/invoice'));
const Category = lazy(() => import('./views/category/category'));
const CategorySorting = lazy(() => import('./views/category/categorySorting'));
const Addon = lazy(() => import('./views/addon/addon'));
const StoreMenu = lazy(() => import('./views/storeMenu/storeMenu'));
const Orders = lazy(() => import('./views/orders/orders'));
const Orders_mac = lazy(() => import('./views/orders/orders_mac'));
const Offers = lazy(() => import('./views/offer/offer'));
const AccountStatement = lazy(() => import('./views/accountStatement/accountStatement'));

const CustomService = lazy(() => import('./views/customService/customService'));
const Faq = lazy(() => import('./views/faq/faq'));
const Dashboard = lazy(() => import('./views/dashboard/dashboard'));

const Login = lazy(() => import('./views/login/login'));
const Forgot = lazy(() => import('./views/login/forgot'));
const ForgotSent = lazy(() => import('./views/login/forgotSent'));
const passwordChanged = lazy(() => import('./views/login/passwordChanged'));
const ResetPassword = lazy(() => import('./views/login/resetPassword'));
const TransactionsList = lazy(() => import('./views/transactions/list'));
const TransactionsView = lazy(() => import('./views/transactions/view'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname

const listofPages = [
    /* See full project for reference */
    //"/login",
    "/home",
    "/website",
    "/orders",
    "/menu",
    "/menu/categories",
    "/menu/categoriesSorting",
    "/menu/product",
    "/menu/dashboard/:storeId",                   
    "/menu/addons",
    // "/menu/offers",
    "/settings",
    "/settings/profile",
    "/settings/schedule",
    "/settings/managers",
    "/settings/invoice",
    "/help",
    "/help/customerService",
    "/help/faq",
    "/website/brand",
    "/settings/transactions",
    "/settings/transactions/view",
];

const Routes = ({ location }) => {
    if(location.pathname.includes("index.php"))
    {
        location.pathname = "/login"
        //console.log("--- Routes2 ----", "Replace Location =", location);
    }

    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    
    let access_token = window.localStorage.jwt_access_token;
    let userData = window.localStorage.userData;

    // if(access_token !== undefined && access_token != null && userData !== undefined && userData != null && currentKey === "/")
    // {
    //     let old_access_token = axios.defaults.headers.common[AXIOS_AUTH];
    //     console.log("old_access_token =", old_access_token);
    //     if(old_access_token !== undefined && old_access_token !== null)
    //     {
    //         axios.defaults.headers.common[AXIOS_AUTH] = 'Bearer ' + access_token;
    //         location.pathname = '/home';
    //     }
    // }

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    const animationName = 'rag-fadeIn'
    
    const os = getOS();
    const browser = getBrowserId();

    if(access_token === undefined || access_token === null || listofPages.indexOf(location.pathname) === -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        {/* See full project for reference */}
                        <Route path="/login" component={waitFor(Login)}/>

                        <Route path="/forgot" component={waitFor(Forgot)}/>
                        <Route path="/forgot_sent" component={waitFor(ForgotSent)}/>
                        <Route path="/reset_password/:id" component={waitFor(ResetPassword)}/>
                        <Route path="/password_chanded_successfully" component={waitFor(passwordChanged)}/>

                        <Redirect to="/login"/>
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <BaseHorizontal>
                <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <OrdersNotification/>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                <Route path="/singleview" component={waitFor(SingleView)}/>
                                <Route path="/submenu" component={waitFor(SubMenu)}/>
                                <Route path="/home" component={waitFor(Home)}/>

                                <Route exact path="/website" component={waitFor(Website)}/>
                                {
                                    (os === OS.MacOS || browser === BROWSER_DEVICE_ID.SAFARI ) 
                                    ? <Route exact path="/orders" component={waitFor(Orders_mac)}/>
                                    : <Route exact path="/orders" component={waitFor(Orders)}/>
                                }
                                

                                <Route exact path="/menu" component={waitFor(Menu)}/>
                                <Route path="/menu/categories" component={waitFor(Category)}/>
                                <Route path="/menu/categoriesSorting" component={waitFor(CategorySorting)}/>
                                <Route path="/menu/product" component={waitFor(StoreMenu)}/>
                                <Route path="/menu/dashboard/:storeId" component={waitFor(Dashboard)}/>                    
                                <Route path="/menu/addons" component={waitFor(Addon)}/>
                                {/* <Route path="/menu/offers" component={waitFor(Offers)}/> */}

                                <Route exact path="/settings" component={waitFor(Settings)}/>
                                <Route path="/settings/profile" component={waitFor(Profile)}/>
                                <Route path="/settings/schedule" component={waitFor(Schedule)}/>
                                <Route path="/settings/managers" component={waitFor(Manager)}/>

                                <Route path="/settings/invoice" component={waitFor(Invoice)}/>

                                <Route exact path="/help" component={waitFor(Help)}/>
                                <Route path="/help/customerService" component={waitFor(CustomService)}/>
                                <Route path="/help/faq" component={waitFor(Faq)}/>

                                <Route path="/website/brand" component={waitFor(Brand)}/>

                                <Route path="/settings/transactions/view" component={waitFor(TransactionsView)}/>
                                <Route path="/settings/transactions" component={waitFor(TransactionsList)}/>

                                <Redirect to="/login"/>                        
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
              </TransitionGroup>
            </BaseHorizontal>
        )
    }
}

export default withRouter(Routes);
