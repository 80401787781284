/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.3
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */
//import './@fake-db';
// Vendor dependencies
import "./Vendor" ;
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';
import LoginService from './services/loginService';
import { JWT_ACCESS_TOKEN } from './components/Common/constants';
import EnvDecode from './common/EnvDecode';

let deferredPrompt; // Variable should be out of scope of addEventListener method

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

class App extends Component {
  state = {
    googleApiQuery: '',
    autoCompleteRef: null,
  }

  UNSAFE_componentWillMount() {
    let access_token = localStorage.getItem(JWT_ACCESS_TOKEN);
    if (access_token !== undefined && access_token !== null) {
      LoginService.setSession(access_token);
    }

    /// For Mobile ///
    window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();	// This prevents default chrome prompt from appearing
                                
        deferredPrompt = e;	 // Save for later
        let infoBar = document.querySelector('#custom-info-bar');
        if (infoBar) {
            infoBar.style.display = '';
            let installBtn = document.querySelector('#custom-install-button');
                                
            installBtn.addEventListener('click', (e) => {
            //This prompt window
            deferredPrompt.prompt();
            // Here we wait for user interaction
            deferredPrompt.userChoice
                .then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        let infoBar = document.querySelector('#custom-info-bar');
                        infoBar.style.display = 'none'; // Hide info bar
                        deferredPrompt = null; // not need anymore
                    }
                });
            });
        }
    });
    /// For Mobile ///

    // window.addEventListener("beforeunload", this.onBeforeUnload);
    // window.addEventListener("unload", this.onUnload);
  }

  onBeforeUnload = (e) => {
    var confirmMessage = "\o/";
        (e || window.event).returnValue = confirmMessage;
        return confirmMessage;
  }

  onUnload = (e) => {
    console.log("Unload => ", e)
  }

  // Detects if device is on iOS
  isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|macintosh/.test(userAgent);
  }
  
  // Detects if device is on iOS
  isSafari = () => {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
  }  
  
  isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
  isMobile = () => {
    return window.innerWidth < 1025
  }

  render() {
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    //const basename = '/' ; 
    //const basename = process.env.NODE_ENV === 'development' ? '/' : (process.env.PUBLIC_URL || '/');
    const basename = process.env.NODE_ENV === 'development' ? '/' : (process.env.PUBLIC_URL || '/');
    //console.log("BaseName = ", basename, ", PUBLIC_URL=", process.env.PUBLIC_URL);
    var browser = (function() {
      var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
      switch (true) {
          case test(/edg/i): return "Microsoft Edge";
          case test(/trident/i): return "Microsoft Internet Explorer";
          case test(/firefox|fxios/i): return "Mozilla Firefox";
          case test(/opr\//i): return "Opera";
          case test(/ucbrowser/i): return "UC Browser";
          case test(/samsungbrowser/i): return "Samsung Browser";
          case test(/chrome|chromium|crios/i): return "Google Chrome";
          case test(/safari/i): return "Apple Safari";
          default: return "Other";
      }
    })();

    console.log("Browser=>", browser)

     // For Google Map Api
    let google_api = EnvDecode.getEnvPath(process.env.React_App_GOOGLE_MAP_API_KEY)
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${google_api}&libraries=places`,
      () => handleScriptLoad(this.state.googleApiQuery, this.state.autoCompleteRef)
    );

    function handleScriptLoad(updateQuery, autoCompleteRef) {
      // autoComplete = new window.google.maps.places.Autocomplete(
      //   autoCompleteRef.current,
      //   // { types: ["(cities)"],
      //   //   //componentRestrictions: { country: "nl" }
      //   // }
      // );
      // autoComplete.setFields(["address_components", "formatted_address"]);
      // autoComplete.addListener("place_changed", () =>
      //   handlePlaceSelect(updateQuery)
      // );
    }

    return (
        <BrowserRouter basename={basename}>
            <Routes />
        </BrowserRouter>
    );

  }
}

export default App;
