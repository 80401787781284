import { observer } from "mobx-react"
import ReactDOMServer from "react-dom/server";
import React, {useEffect, useState} from 'react';
import { 
    CardBody, Modal, ModalHeader, ModalBody, Row
} from 'reactstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as actions from '../../store/actions/orders.actions';
import * as allActions from '../../store/actions/actions';
import _ from 'lodash';
import utilService from '../../common/utilService'
import CDropDwon from '../../components/Common/CustomDropDown/CDropDown';
import {COLORS, ORDER_SERVICE_TYPE, ORDER_STATUS, ORDER_BOOKING_TYPE} from '../../components/Common/constants';
import MainStore from '../../store/notifyStore';
import OrderDetail from "../../views/orders/orderDetail";
import moment from 'moment'

import useSound from 'use-sound'
import tone from './sounds/notification.mp3'

const dropDownItems = [
    { data: `15 ${utilService.getLangByCode('Minutes')}`, id: 0, value:15 },
    { data: `30 ${utilService.getLangByCode('Minutes')}`, id: 1, value:30 },
    { data: `45 ${utilService.getLangByCode('Minutes')}`, id: 2, value:45 },
    { data: `60 ${utilService.getLangByCode('Minutes')}`, id: 3, value:60 }
]
///////////////////////
const OrdersNotification = observer((props) => {

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [delay, setDelay] = useState(30);
    const [selectedOrderId, setSelectedOrderId] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [templateString, setTemplateString] = useState(null);

   
    const [playbackRate, setPlaybackRate] = useState(0.75);
    const [play, {stop}] = useSound(tone, {
        playbackRate,
        loop:true,
        interrupt: true,
      });

    useEffect(() => {
      if (!props.profile) {
        props.getProfile();
      }
    }, []);

    useEffect(() => {
      if (templateString) {
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    }, [templateString]);

    //const {isOpen} = props;
    const getDefaultDropDownIndex = (value) => {
        if (value == 15){
            return 0;
        } else if(value == 30) {
            return 1;
        } else if(value == 45) {
            return 2;
        }
        return 3;
    }

    const getFee = (order_item) => {
        if (order_item.currencySymbol && order_item.deliveryCharge)
        {
            return(
                <div>{order_item.currencySymbol + order_item.deliveryCharge.toFixed(2)}</div>
            )
        }
        
        return ( <div>{order_item.currencySymbol + '0'}</div> )
    }

    const getFeeAmount = (order) => {
        if (order.deliveryCharge)
        {
            return order.deliveryCharge.toFixed(2)
        }
        
        return 0;
    }
    
    const getTotalAmount = (order) => {
        return order.currencySymbol + (parseFloat(order.subTotalAmount) + parseFloat(getFeeAmount(order))).toFixed(2);
    }


    const getCurrentISODateTime = () => {
        let local_date = new Date()
        let utc_date = new Date( local_date.getUTCFullYear(), local_date.getUTCMonth(), local_date.getUTCDate(), 
            local_date.getUTCHours(), local_date.getUTCMinutes(), local_date.getUTCSeconds() );
        return utc_date;
    }

    const calcuExpiredTime = (order) => {
        let dateTime = order.dueDatetime //order.bookingDate
        let dueDate = new Date(dateTime)
        var dueSeconds = dueDate.getTime() / 1000;

        // let iso_date = getCurrentISODateTime();
        // let curUTCseconds = Math.floor(iso_date.getTime() / 1000)
        let cur_date = new Date();
        let curUTCseconds = Math.floor(cur_date.getTime() / 1000)

        var ready_time;
        if(order.bookingType === ORDER_BOOKING_TYPE.ASAP) {
            ready_time = (dueSeconds - curUTCseconds) / 60 + 10; // + 5min
        }
        else
        {
            ready_time = (dueSeconds - curUTCseconds) / 60; 
        }

        return parseInt(ready_time)/* + delay */
    }

    const processAutoPrint = async (msg) => {
        // get print string
        const printData = ReactDOMServer.renderToStaticMarkup(
          <OrderDetail
            order={msg}
            onCloseDetail={() => {}}
            isTemplateMode={true}
          />
        );
        // sleep for 5 seconds
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 5000);
        });
    
        // set new data for print and print after 1 second
        setTemplateString(printData)
      }

    const autoPrint = (order) => {
        const storeProfile = props.profile.storeProfileData;
        if(storeProfile.autoPrintOrder) {
            processAutoPrint(order)
        }
    }

    const OrderAccept = (orderIdString, index, expired_time, order = null) => {
        let orderId = orderIdString
        let status = ORDER_STATUS.accepted
        let reason = "day limited"

        // let iso_date = getCurrentISODateTime();
        // let timestamp = Math.floor(iso_date.getTime() / 1000)
        let timestamp = Date.parse(new Date()) / 1000  // Local time
        timestamp += expired_time * 60
        
        let user_data = JSON.parse(localStorage.getItem('userData'));

        let req = {
            "orderId": orderId,
            "status": ORDER_STATUS.accepted,
            "reason": "day limited",
            "timestamp": timestamp,
            "userId": user_data.id,
            "notifyIndex":index
        }
        // console.log("***Accept***", MainStore.notifications)
        props.actions.updateOrderStatus(req)
        MainStore.notifications.splice(0, 1);
        autoPrint(order);
    }

    /* const OrderDeny = (orderIdString, index) => {
        let orderId = orderIdString

        //let timestamp = Date.parse(new Date()) / 1000
        let iso_date = getCurrentISODateTime();
        let timestamp = Math.floor(iso_date.getTime() / 1000)

        let req = {
            "orderId": orderId,
            "status": ORDER_STATUS.rejected,
            "reason": "day limited",
            "timestamp": timestamp,
            "userId": JSON.parse(localStorage.getItem('userData')).id,
            "notifyIndex":index
        }
        setShowAlert(false)

        MainStore.notifications.splice(0, 1);
        props.actions.updateOrderStatus(req)
    } */
    
    /* const onPressedDeny = (orderIdString, orderIndex) => {
        if(orderIdString === 0)
        {
            MainStore.notifications.splice(0, 1);
            return;
        }

        stop(); // stop sound
        
        setShowAlert(true);
        setSelectedOrderId(orderIdString);
        setSelectedIndex(orderIndex);
    } */

    const onCancel = () => {
        setShowAlert(false);
    }

    const onPressedConfirm = (orderIdString, orderIndex, order) => {
        //console.log("***Confirm-OrderID***", orderIdString)

        stop(); // stop sound

        if(orderIdString === 0)
        {
            MainStore.notifications.splice(0, 1);
            return;
        }

        if(order.bookingType === ORDER_BOOKING_TYPE.ASAP)
        {
            OrderAccept(orderIdString, orderIndex, delay, order)
        }
        else
        {
            OrderAccept(orderIdString, orderIndex, calcuExpiredTime(order), order)
        }
    }

    const onNoAccept = () => {
        if(!showAlert && MainStore.notifications.length > 0 && MainStore.notifications[0].status == ORDER_STATUS.created)
        {
            let userData = JSON.parse(localStorage.getItem('userData'))

            MainStore.notifications.splice(0, 1);
            stop(); // stop sound

            props.actions.getOrders({ 
                cityId:         userData.cityId, 
                index:          0, 
                franchiseId:    userData.franchiseId, 
                storeId:        userData.storeId, 
                bookingType:    0,
                serviceType:    0,
                fromDate:       0, 
                toDate:         0, 
                search:         0 
            });
        }
    }

    const getAddonName = (addedOnData) => {
        //console.log("AddOnData = ", addedOnData)
        if(typeof addedOnData === "object") {
            if(typeof addedOnData.name === "string")
            {
                return addedOnData.name
            }
            else
            {
                return localStorage.getItem('jq-appLang') === "en" ? addedOnData.name.en : addedOnData.name.nl
            }
        }

        return addedOnData.name;
    }
        return (
            <>
                {templateString ? (
                <div
                    style={{
                    position: "fixed",
                    width: "100%",
                    left: 0,
                    top: 0,
                    visibility: "hidden",
                    }}
                    dangerouslySetInnerHTML={{ __html: templateString }}
                />
                ) : null}
                
                <Modal                    
                    isOpen={MainStore.notifications.length > 0}
                    //visible = {MainStore.visible}
                    toggle={() => {
                        //onNoAccept();
                    }}

                    onOpened={() => {
                        //console.log("** Notification opened**")

                        if( MainStore.notifications.length > 0 
                            && MainStore.notifications[0].status == ORDER_STATUS.created) 
                        {
                            //console.log("** New Order Received **")
                            stop();
                            setPlaybackRate(playbackRate + 0.1);
                            play();
                        }
                    }}

                    onClosed={() => {
                        //console.log("** Notification closed**")
                        stop();
                    }}
                    style={{ marginTop:'200px', lineHeight:'0.05rem'}}
                    className="font-arial-bold"
                >

                    {/* <div class="scroll" style={{maxHeight:'500px', overflowY:'scroll'}}> */}
                        {MainStore.notifications.map((item, i) => {
                            let orderNum = item.orderId
                            let user_id = JSON.parse(localStorage.getItem('userData')).id
                            // console.log("** Notification render**", orderNum, item)
                            return(
                                <div key={"key" + i}>
                                    { i == 0 && <ModalHeader className="text-center" style={{marginTop:'100px', padding:'0', border:0}}>
                                        <div style={{marginTop:"-170px"}}>
                                            <img className="img-fluid" src="img/notify_logo.svg" alt="App Logo" style={{width:'140px', height:'140px'}}/>
                                        </div>
                                        { 
                                            !showAlert && item.status === ORDER_STATUS.created && item.serviceType == ORDER_SERVICE_TYPE.delivery &&
                                            <p style={{color:COLORS.GENERAL, fontSize:'20px'}}>
                                                {utilService.getLangByCode('Delivery order')}
                                            </p>
                                        }
                                        { 
                                            !showAlert && item.status === ORDER_STATUS.created && item.serviceType == ORDER_SERVICE_TYPE.pickup &&
                                            <p style={{color:COLORS.GENERAL, fontSize:'20px'}}>
                                                {utilService.getLangByCode('Pickup order')}
                                            </p>
                                        }
                                        { 
                                            !showAlert && item.status === ORDER_STATUS.accepted &&
                                            <p style={{color:COLORS.GENERAL, fontSize:'20px'}}>
                                                {utilService.getLangByCode('Accepted order')}
                                            </p>
                                        }
                                        { 
                                            !showAlert && (item.status === ORDER_STATUS.orderReady || item.status === ORDER_STATUS.orderPicked || item.status === ORDER_STATUS.inDispatch) &&
                                            <p style={{color:COLORS.GENERAL, fontSize:'20px'}}>
                                                {utilService.getLangByCode('Delivery/Pickup ready order')}
                                            </p>
                                        }

                                        { 
                                            !showAlert && (item.status === ORDER_STATUS.orderCompleted || item.status === ORDER_STATUS.orderDeliveryCompleted) &&
                                            <p style={{color:COLORS.GENERAL, fontSize:'20px'}}>
                                                {utilService.getLangByCode('Completed order')}
                                            </p>
                                        }
                                        { 
                                            !showAlert && item.status === ORDER_STATUS.orderExpired &&
                                            <p style={{color:COLORS.GENERAL, fontSize:'20px'}}>
                                                {utilService.getLangByCode('Expired order')}
                                            </p>
                                        }
                                        { 
                                            !showAlert && item.status === ORDER_STATUS.cancelled &&
                                            <p style={{color:COLORS.GENERAL, fontSize:'20px'}}>
                                                {utilService.getLangByCode('Cancelled order')}
                                            </p>
                                        }
                                        { 
                                            !showAlert && item.status === ORDER_STATUS.rejected &&
                                            <p style={{color:COLORS.GENERAL, fontSize:'20px'}}>
                                                {utilService.getLangByCode('Rejected order')}
                                            </p>
                                        }
                                    </ModalHeader>
                                    }
                                    { !showAlert && i == 0  &&<div>
                                            <ModalBody style={{marginLeft:'30px', marginRight:'30px', fontSize:'16px', lineHeight: 1}}>
                                                <hr style={{borderTopWidth:'2px', margin:0}}/>
                                                <CardBody className="d-flex" style={{justifyContent:'space-between', paddingLeft:0, paddingRight:0}}>
                                                    <div>{utilService.getLangByCode('Order placed at')}</div>
                                                    <div style={{color:'#21478F'}}>
                                                        {
                                                            //moment(item.bookingDate).format('MM.DD.YY') + "-" + moment(item.bookingDate).format('HH:mm')
                                                            moment(item.timeStamp.created.isoDate).format('DD/MM/YYYY-HH:mm')
                                                        }
                                                    </div>
                                                </CardBody>
                                                {
                                                    item.bookingType === ORDER_BOOKING_TYPE.SCHEDULE &&
                                                    <CardBody className="d-flex" style={{justifyContent:'space-between', paddingLeft:0, paddingRight:0}}>
                                                        <div>{utilService.getLangByCode('Desired time')}</div>
                                                        <div style={{color:'#21478F'}}>
                                                            {moment(item.dueDatetime).format('DD/MM/YYYY') + "-" + moment(item.dueDatetime).format('HH:mm')}
                                                        </div>
                                                    </CardBody>
                                                } 
                                                <CardBody className="d-flex" style={{justifyContent:'space-between', paddingLeft:0, paddingRight:0}}>
                                                    <div>{utilService.getLangByCode('Delivery address')}</div>
                                                    {
                                                        item.serviceType === ORDER_SERVICE_TYPE.delivery && 
                                                        <div style={{color:'#21478F', textAlign:'end'}}>
                                                            {item.drop.addressLine1}
                                                        </div>
                                                    }
                                                    {
                                                        item.serviceType === ORDER_SERVICE_TYPE.pickup && 
                                                        <div style={{color:'#21478F'}}>
                                                            {/* {utilService.getLangByCode("Address")} */}
                                                        </div>
                                                    }
                                                </CardBody>
                                                <CardBody className="d-flex" style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0 }}>
                                                    <div>{utilService.getLangByCode('Customer details')}</div>
                                                    <div style={{ color: '#21478F' }}>
                                                        <span>
                                                            <span>{item.customerDetails.name} - </span>
                                                            <span>{`(${item.customerDetails.countryCode}) ${item.customerDetails.mobile}`}</span>
                                                        </span>
                                                    </div>
                                                </CardBody>   
                                                <hr style={{borderTopWidth:'2px', margin:0}}/>
                                                <CardBody className="d-flex" style={{justifyContent:'space-between', color:'#21478F', fontWeight:'bold', paddingLeft:0, paddingRight:0}}>
                                                    <div>{utilService.getLangByCode('Item')}</div>
                                                    <div>{utilService.getLangByCode('Price')}</div>
                                                </CardBody>   
                                                <hr style={{borderTopWidth:'2px', margin:0}}/>
                                                {
                                                    item.Items && item.Items.map((data, index) => {
                                                        return (
                                                            <div style={{paddingLeft:0, paddingRight:0}} key={`SubItem${index}`}>
                                                                <CardBody className="d-flex" style={{justifyContent:'space-between', alignItems:'center', paddingLeft:0, paddingRight:0}}>
                                                                    <div style={{flex: 2}}>{data.itemName}</div>
                                                                    <div style={{flex: 1}}>{data.quantity + " x " + item.currencySymbol +data.unitPrice.toFixed(2)}</div>
                                                                    <div style={{color:'#21478F'}}>{item.currencySymbol + (data.quantity * data.unitPrice).toFixed(2)}</div>
                                                                </CardBody>
                                                                { data.addOns && data.addOns.map((addOnData, addOnIndex) => {
                                                                    return(
                                                                        <div key={`addonItem${addOnIndex}`}>
                                                                            {
                                                                                addOnData.addOnGroup && addOnData.addOnGroup.map((addedOnData, sub_index) =>{
                                                                                    if(addedOnData.price !== undefined)
                                                                                    {
                                                                                        return(
                                                                                                <CardBody className="d-flex" style={{justifyContent:'space-between', alignItems:'center', paddingLeft:'30px', paddingRight:0, fontSize:'12px'}} 
                                                                                                    key={`SubAddOnItem${sub_index}`}>
                                                                                                        <div style={{flex: 2}}>{"+" + getAddonName(addedOnData)}</div>
                                                                                                        <div style={{flex: 1}}>{data.quantity + " x " + item.currencySymbol + Number(addedOnData.price).toFixed(2)}</div>
                                                                                                        <div style={{color:'#21478F'}}>{item.currencySymbol + (data.quantity* Number(addedOnData.price)).toFixed(2)}</div>
                                                                                                </CardBody>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <hr style={{borderTopWidth:'2px', margin:0}}/>
                                                <CardBody className="d-flex" style={{justifyContent:'space-between', paddingLeft:0, paddingRight:0}}>
                                                    <div>{utilService.getLangByCode('Delivery fee')}</div>
                                                    <div style={{color:'#21478F'}}>
                                                        { getFee(item)}
                                                    </div>
                                                </CardBody> 
                                                <hr style={{borderTopWidth:'2px', margin:0}}/>
                                                <CardBody className="d-flex" style={{justifyContent:'space-between', color:'#21478F', fontWeight:'bold', paddingLeft:0, paddingRight:0}}>
                                                    <div>{utilService.getLangByCode('Total')}</div>
                                                    {
                                                        item.currencySymbol && item.totalAmount &&
                                                        <div style={{color:'#21478F', fontWeight:'bold'}}>{getTotalAmount(item)}</div>
                                                    }
                                                </CardBody> 
                                                <hr style={{borderTopWidth:'2px', margin:0}}/>
                                                <CardBody className="d-flex" style={{justifyContent:'space-between', color:'#21478F', fontWeight:'bold', paddingLeft:0, paddingRight:0, lineHeight: 1.3}}>
                                                    <div>{utilService.getLangByCode('Notes')}</div>
                                                    <div style={{color:'#21478F', fontWeight:'bold', marginLeft:"30px"}}>{item.extraNote}</div>
                                                </CardBody> 
                                                {
                                                    item.status === ORDER_STATUS.created &&
                                                    <Row className="d-flex" style={{justifyContent:'space-between', paddingLeft:0, paddingRight:0, lineHeight:1}}>
                                                        {/* <div className="d-flex" style={{marginTop:"20px", color:'#21478F'}}>
                                                            <CDropDwon
                                                                items={dropDownItems}
                                                                default={getDefaultDropDownIndex(delay)}
                                                                onChangeItem={(listItem) => {setDelay(listItem.value); //console.log("SET DELAY")}} 
                                                            />
                                                        </div> */}
                                                        {
                                                            item.bookingType !== ORDER_BOOKING_TYPE.SCHEDULE &&
                                                            <div className="d-flex" id="orderNotifyCountDown" style={{marginTop:"20px", color:'#21478F', marginBottom: '25px'}}>
                                                                <CDropDwon
                                                                    items={dropDownItems}
                                                                    default={getDefaultDropDownIndex(delay)}
                                                                    onChangeItem={(listItem) => {
                                                                        setDelay(listItem.value); 
                                                                        //console.log("SET DELAY")
                                                                    }} 
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            item.bookingType === ORDER_BOOKING_TYPE.SCHEDULE &&
                                                            <div></div>
                                                        }
                                                        <div className="d-flex" id="orderConfirmBtnGroup" style={{justifyContent:'end'}}>
                                                            {/* <button 
                                                                className="btn bg-red text-white"
                                                                onClick={() => {onPressedDeny(orderNum, i)}} 
                                                                style={{width:'70px', fontSize:'18px'}}
                                                            >
                                                                {utilService.getLangByCode('Deny')}
                                                            </button> */}
                                                            {
                                                                item.bookingType === ORDER_BOOKING_TYPE.ASAP &&
                                                                <button 
                                                                    className="btn bg-btn-approve text-white"
                                                                    onClick={() => onPressedConfirm(orderNum, i, item)}
                                                                    style={{width:'170px', borderWidth:'2px',fontSize:'18px'}}
                                                                    data-test="confirm_button"
                                                                    name="confirm_button"
                                                                >
                                                                    {utilService.getLangByCode('Confirm')}
                                                                </button>
                                                            }
                                                            {
                                                                item.bookingType === ORDER_BOOKING_TYPE.SCHEDULE &&
                                                                <button 
                                                                    className="btn bg-btn-approve text-white"
                                                                    onClick={() => onPressedConfirm(orderNum, i, item)}
                                                                    style={{width:'170px', borderWidth:'2px',fontSize:'18px'}}
                                                                >
                                                                    {utilService.getLangByCode('Confirm')}
                                                                    {/* {utilService.getLangByCode('Confirm') + ` ${calcuExpiredTime(item)}` + utilService.getLangByCode('min')} */}
                                                                </button>
                                                            }
                                                        </div>
                                                    </Row>
                                                }

                                                {
                                                    item.status !== ORDER_STATUS.created &&
                                                    <CardBody className="d-flex" style={{justifyContent:'center', paddingLeft:0, paddingRight:0}}>
                                                        <div className="d-flex" style={{justifyContent:'center'}}>
                                                            <button 
                                                                className="btn bg-btn-approve text-white"
                                                                onClick={() => {
                                                                    if (MainStore.notifications.length > 1) {
                                                                        MainStore.notifications.splice(i, 1);
                                                                    } else {
                                                                        MainStore.notifications = [];
                                                                    }

                                                                    if(item.status === ORDER_STATUS.orderExpired)
                                                                    {
                                                                        // let iso_date = getCurrentISODateTime();
                                                                        // let timestamp = Math.floor(iso_date.getTime() / 1000)
                                                                        // let req = {
                                                                        //     "orderId": orderNum,
                                                                        //     "status": ORDER_STATUS.orderExpired,
                                                                        //     "reason": "day limited",
                                                                        //     "timestamp": timestamp,
                                                                        //     "userId":  user_id
                                                                        // }
                                                                        // //console.log("Expired=", req)
                                                                        // props.actions.updateExpiredStatus( req )
                                                                    }
                                                                }}
                                                                style={{width:'170px', borderWidth:'2px'}}
                                                                data-test="close_button"
                                                            >
                                                                {utilService.getLangByCode('Close')}
                                                            </button>
                                                        </div>
                                                    </CardBody>
                                                }
                                            </ModalBody>    
                                        </div>
                                    }
                                    
                                </div>
                            )
                        })}
                    {/* </div> */}

                    {
                        showAlert && <div>
                            {/* <ModalHeader className="text-center" style={{marginTop:'100px', padding:'0', border:0}}>
                                <div style={{marginTop:"-170px"}}>
                                    <img className="img-fluid" src="img/notify_logo.svg" alt="App Logo" style={{width:'140px', height:'140px'}}/>
                                </div>
                            </ModalHeader> */}
                            <ModalBody style={{paddingLeft:'120px', paddingRight:'120px', fontSize:'24px', textAlign:'center', lineHeight:'3rem'}}>
                                <div className="d-flex" style={{paddingLeft:0, paddingRight:0, justifyContent:'center'}}>
                                    <div style={{textAlign:'center', color:COLORS.GENERAL}}>{utilService.getLangByCode('Are you sure you want to deny a new order?')}</div>
                                </div>   
                                
                                <CardBody className="d-flex" style={{justifyContent:'center', paddingLeft:0, paddingRight:0}}>
                                        <button 
                                            className="btn"
                                            onClick={() => onCancel()}
                                            style={{width:'100px', borderWidth:'2px', borderColor:'#A7BA13', color:'#A7BA13', marginRight:'20px',fontSize:'18px'}}
                                        >
                                            {utilService.getLangByCode('Cancel')}
                                        </button>
                                        {/* <button 
                                            className="btn bg-red text-white"
                                            onClick={() => {OrderDeny(selectedOrderId, selectedIndex)}} 
                                            style={{width:'100px', marginLeft:'20px',fontSize:'18px'}}
                                        >
                                            {utilService.getLangByCode('Deny')}
                                        </button> */}
                                </CardBody>
                            </ModalBody>  
                        </div>
                    }
                
                <div style={{marginBottom:'20px'}} />

                </Modal>
            </>
        );
})

//export default OrdersNotification;
const mapStateToProps = ({orders, auth}) => 
    ({ err: orders.err_order, msg:orders.msg, 
        accepted_orders: orders.accepted_orders,
        inDispatch_orders: orders.inDispatch_orders,
        completed_orders: orders.completed_orders,
        new_orders: orders.new_orders,
        packageOrder: orders.packageOrder,
        pickUpReady_orders: orders.pickUpReady_orders,
        deliveryReady_orders: orders.deliveryReady_orders,
        pickUp_orders: orders.pickUp_orders,
        completed_orders: orders.completed_orders,

        orders_count: orders.orders_count,
        profile: auth.profile,
        userId:auth.userData.id,
        userData: auth.userData })
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { ...actions },
    dispatch
  ),
  getProfile: allActions.getProfile,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersNotification);
